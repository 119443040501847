// ================================================================================================
// 	File Name: dark-layout.scss
// 	Description: SCSS file for dark layout.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Load variable overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

$calendarPastMonth: #6a6d83;

body {
  &.dark-layout {
    background-color: $content-dark-bg;

    // universal elements
    //heading tags
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $theme-dark-heading-color;
    }

    // border color
    [class*="border-"] {
      &:not(.bullet):not(.spinner-border) {
        border-color: $theme-dark-border-color !important;
      }
    }

    .border {
      border-color: $theme-dark-border-color !important;
    }

    // other tags
    a {
      &:hover {
        color: $primary;
      }
    }

    p,
    small,
    span,
    label {
      color: $theme-dark-text-color;
    }

    hr {
      border-color: $theme-dark-border-color;
    }

    // code, pre, kbd
    pre {
      background-color: $content-dark-bg;
      border: 0;

      code {
        background-color: inherit;
        text-shadow: none;

        .operator,
        .url {
          background-color: $content-dark-bg;
        }
      }
    }

    code {
      background-color: $content-dark-bg;
      color: $gray-600;
    }

    kbd {
      background-color: $content-dark-bg;
    }

    // Code component
    .component-code {
      pre {
        &::-webkit-scrollbar-thumb {
          background: $theme-dark-text-color;
        }

        &::-webkit-scrollbar-track {
          background: $grid-dark-color;
        }
      }
    }

    // for dark text
    .text-dark {
      color: $gray-600 !important;
    }

    // main navbar and header navbar shadow(generic navbar)
    .header-navbar-shadow {
      background: linear-gradient(180deg,
          rgba(44, 48, 60, 0.9) 44%,
          rgba(44, 48, 60, 0.43) 73%,
          rgba(17, 30, 70, 0));
    }

    .header-navbar {
      background-color: $dark-card-color;

      .navbar-container {

        .nav,
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: $theme-dark-text-color;
              background-color: transparent;

              svg {
                stroke: $theme-dark-text-color;
              }
            }

            &.nav-search {
              .search-input {
                &.open {
                  background-color: $dark-card-color;

                  .input {
                    border-color: $theme-dark-border-color;
                  }

                  .input,
                  .input::placeholder,
                  .search-input-close {
                    color: $theme-dark-text-color;
                  }
                }
              }
            }

            .bookmark-input {
              .form-control {
                background-color: $dark-card-color;
              }
            }

            .search-list {
              background-color: $content-dark-bg;

              .current_item {
                background-color: $dark-card-color;
              }
            }
          }

          .dropdown-notification {
            .dropdown-menu.dropdown-menu-media {
              overflow: hidden;

              &:before {
                display: none;
              }

              .media {
                border-color: $theme-dark-border-color;

                .media-body {
                  .notification-text {
                    //color: $theme-dark-text-color;
                  }
                }

                .media-meta {
                  color: $theme-dark-text-color;
                }

                &:hover {
                  background-color: $dark-card-color;
                }
              }

              .dropdown-menu-footer {
                background-color: $dark-card-color;

                .dropdown-item {
                  color: $white;
                  border-color: $theme-dark-border-color;

                  &:hover {
                    background-color: $dark-card-color;
                  }
                }
              }
            }

            .dropdown-cart {
              &.empty-cart {
                &:before {
                  background-color: $content-dark-bg;
                }
              }

              li {
                color: $white;
              }

              .dropdown-header {
                span {
                  color: $white;
                }
              }

              .media-list {
                .media {
                  background-color: $white;

                  .media-body {
                    background-color: $content-dark-bg;
                    padding-left: 1rem !important;
                  }

                  &:hover {
                    background-color: $white !important;
                  }
                }
              }
            }
          }
        }
      }

      &.navbar-static-top {
        background-color: transparent;
      }

      &[class*="bg-"] {
        .navbar-nav {
          .nav-item {
            .nav-link {
              background-color: inherit;

              svg {
                stroke: $white;
              }
            }
          }
        }
      }

      &.bg-dark {
        background-color: $gray-500 !important;
      }
    }

    // main menu
    .main-menu {
      background-color: $dark-card-color;

      .shadow-bottom {
        background: linear-gradient(180deg,
            #0f1642 44%,
            rgba(15, 22, 66, 0.51) 73%,
            rgba(44, 48, 60, 0));
      }

      &:not(.expanded) {
        .navigation {
          .sidebar-group-active {
            a {
              background-color: $content-dark-bg;
            }
          }
        }
      }

      &.theme-dark {
        .navbar-header {
          .brand-text {
            color: $gray-500 !important;
          }
        }

        .navigation {
          .nav-item {
            &.active {
              a {
                background: linear-gradient(118deg,
                    $gray-500,
                    rgba($gray-500, 0.7));
                box-shadow: 0 0 10px 1px rgba($gray-500, 0.7);
              }
            }

            .menu-content {
              li.active {
                background: linear-gradient(118deg,
                    $gray-500,
                    rgba($gray-500, 0.7));
                box-shadow: 0 0 10px 1px rgba($gray-500, 0.7);
              }
            }
          }
        }
      }
    }

    // main menu content
    .main-menu-content {
      .navigation-main {
        background-color: $dark-card-color;

        .nav-item {
          .menu-text {
            color: $theme-dark-text-color;
          }

          svg {
            stroke: $theme-dark-text-color;
          }

          &.open {
            a {
              background-color: $content-dark-bg;
            }
          }

          a:after {
            color: $theme-dark-text-color;
          }

          .menu-content {
            background-color: $dark-card-color;

            .active {
              .menu-item {
                color: $white;
              }
            }

            li:not(.active) {
              a {
                background-color: $dark-card-color;
                color: $theme-dark-text-color;
              }
            }
          }
        }

        .active {

          .menu-text,
          svg,
          span {
            color: $white;
            stroke: $white;
          }
        }

        .sidebar-group-active {
          a {
            background: $dark-card-color;
            border-radius: 4px;
          }

          .menu-content {
            background-color: $dark-card-color;

            .active {
              z-index: 1;

              a {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    // menu collapsed active item color
    &.menu-collapsed {
      .main-menu:not(.expanded) {
        .navigation-main {
          li.active {
            a {
              background: $content-dark-bg !important;
            }
          }
        }
      }
    }

    /********* UI ELEMENTS *********/

    // Grid
    .bd-example {

      .row,
      .d-flex {
        background-color: $content-dark-bg;

        .col,
        [class*="col-"],
        .bd-highlight {
          background-color: $content-dark-bg;
          border-color: $ex-col-border-color;
        }
      }

      .height-example-wrapper {
        background-color: $grid-dark-color !important;
      }

      .width-example,
      .height-example {
        background-color: $content-dark-bg !important;
      }
    }

    .bd-example-row-flex-cols {
      .row {
        background-color: $content-dark-bg;
      }
    }

    //colors
    .colors-container {
      span {
        color: $white;
      }
    }

    /****** Data List ******/

    .data-list {
      .add-new-btn {
        span {
          color: $primary;
        }
      }

      .rdt_Table {
        background-color: $theme-dark-table-bg;

        .rdt_TableCol,
        .rdt_TableCol div {
          color: $theme-dark-text-color !important;
        }

        .rdt_TableRow {
          background-color: $content-dark-bg;
          color: $theme-dark-text-color;

          .progress {
            background-color: $theme-dark-table-bg;
          }
        }
      }

      .sort-dropdown {
        background-color: $dark-card-color;
        border-color: $theme-dark-border-color;

        svg {
          color: $theme-dark-text-color;
        }
      }

      .filter-section {
        input.form-control {
          background-color: $dark-card-color;
        }

        &:after {
          color: $theme-dark-text-color;
        }
      }

      .vx-pagination {
        li {
          background-color: $dark-card-color;
        }
      }

      .data-list-sidebar {
        background-color: $dark-card-color;

        .data-list-sidebar-header,
        .data-list-fields {
          border-color: $theme-dark-border-color;

          svg {
            color: $theme-dark-text-color;
          }
        }
      }
    }

    /********* COMPONENTS *********/

    // alerts
    .alert {

      .alert-heading,
      p {
        color: inherit;
      }

      &.alert-dark {
        color: $gray-600 !important;

        .alert-heading,
        p {
          color: $gray-600;
        }
      }
    }

    // breadcrumbs
    .content-header-left {
      .breadcrumbs-top {
        .content-header-title {
          color: $theme-dark-heading-color;
        }

        .breadcrumb-wrapper {
          .breadcrumb {
            .breadcrumb-item {
              color: $theme-dark-text-color;

              &:before {
                color: $theme-dark-text-color;
              }

              &:not(.active) {
                a:hover {
                  color: $primary;
                }
              }
            }
          }
        }
      }
    }

    // for demo breadcrumb page
    #breadcrumb-slash,
    #breadcrumb-alignment {
      .breadcrumb {
        .breadcrumb-item {
          color: $theme-dark-text-color;

          &:before {
            color: $theme-dark-text-color;
          }

          &:not(.active) {
            a:hover {
              color: $primary;
            }
          }
        }
      }
    }

    #component-breadcrumbs {
      .breadcrumb {
        .breadcrumb-item {
          &:not(.active) {
            a {
              color: $theme-dark-text-color;
            }
          }

          &:before {
            color: $theme-dark-text-color;
          }

          &.active {
            span {
              color: $primary;
            }
          }
        }
      }
    }

    // collapse
    // for shadow and bordered
    .collapse-bordered {
      .collapse-header {
        border-color: rgba($white, 0.04);
      }

      &.accordion-shadow {
        box-shadow: 0 0 8px 0 rgba($white, 0.1);
      }

      .card {
        border-color: $theme-dark-border-color;
      }
    }

    .accordion {
      .collapse-border-item {
        border-color: rgba($white, 0.04);
      }
    }

    // margin collapse
    .collapse-margin {
      box-shadow: 0 2px 15px 0 rgba($white, 0.1);
    }

    // for hover collapse
    .accordion,
    .collapse {
      &[data-toggle-hover="true"] {
        .card {
          border-color: rgba($white, 0.04);
        }
      }
    }

    // if there is an anchor tag inside
    .collapse-icon {
      .card-header {
        a {
          color: $theme-dark-text-color;
        }
      }
    }

    // buttons
    .btn {
      span {
        color: $white;
      }

      &.btn-white {
        span {
          color: $black;
        }
      }

      &.btn-icon {
        i {
          color: $white;
        }
      }

      &.btn-dark,
      &.btn-outline-dark,
      &.btn-flat-dark {
        color: $gray-600;
      }

      &.btn-dark {
        background-color: $gray-700 !important;
      }

      &.btn-outline-dark {
        border-color: $gray-700;
        color: $gray-600;
      }

      &.btn-flat-dark {

        &:active,
        &:focus {
          background: $gray-700;
        }
      }

      &.btn-white {
        color: $body-color;
      }
    }

    // drodpown
    .dropdown-toggle {
      color: $white;

      &:hover {

        i,
        &:after {
          color: $white;
        }
      }
    }

    .dropdown-menu {
      background-color: $content-dark-bg;

      &:before,
      .dropdown-item:hover,
      .dropdown-item:focus {
        background: $content-dark-bg;
      }

      .dropdown-item {
        color: $theme-dark-text-color;
      }

      .form-control {
        background-color: $dark-card-color;
      }

      .dropdown-divider {
        border-color: $theme-dark-border-color;
      }
    }

    .btn-white~.dropdown-menu {
      .dropdown-item:not(.acitve):hover {
        color: $primary !important;
      }
    }

    .btn-dark,
    .btn-outline-dark,
    .btn-flat-dark {
      &~.dropdown-menu {
        .dropdown-item:hover {
          color: $theme-dark-text-color;
        }
      }
    }

    // dropdown icon
    .dropup {
      &.dropdown-icon-wrapper {
        .dropdown-menu {
          .dropdown-item {
            i {
              color: $theme-dark-text-color;
            }
          }
        }
      }
    }

    // modal
    .modal {

      .modal-header,
      .modal-header[class*="bg-"] {
        background-color: $dark-card-color !important;

        .close {
          background-color: $content-dark-bg;
          text-shadow: none;

          span {
            color: $white;
          }
        }
      }

      .modal-content,
      .modal-body,
      .modal-footer {
        background-color: $content-dark-bg;
        color: $theme-dark-text-color;

        .form-control,
        .picker__input,
        .custom-file-label,
        .custom-file-label:after {
          background-color: $dark-card-color !important;
        }
      }
    }

    //pagination
    .pagination {
      &:not([class*="pagination-"]) {
        .page-item {
          &.active {
            background-color: $content-dark-bg;

            .page-link {
              background-color: $primary;

              &:hover {
                color: $white;
              }
            }
          }

          .page-link {
            background-color: $content-dark-bg;
            color: $white;

            &:hover {
              color: $primary;
            }
          }

          &.prev-item,
          &.next-item {
            .page-link:hover {
              color: $white;
            }
          }
        }
      }

      &[class*="pagination-"] {
        .page-item:not(.active):not(:hover) {
          .page-link {
            background-color: $content-dark-bg;
            color: $white;
          }
        }

        .page-item {
          &:not(.active) {
            .page-link:hover {
              background-color: $content-dark-bg;
            }
          }

          &.active {
            background-color: $content-dark-bg;
          }
        }
      }
    }

    // nav component
    .nav {
      .nav-item {
        .nav-link {
          color: $theme-dark-text-color;

          &.active,
          &:hover {
            color: $primary;
          }
        }
      }

      &.wrap-border {
        border-color: $theme-dark-border-color;
      }
    }

    // navbar
    #navbar-component {
      .basic-navbar {
        .navbar {
          background-color: $content-dark-bg;
        }
      }

      .navbar {

        .nav,
        .navbar-nav {
          .nav-item {
            a {
              color: $white !important;
            }

            .nav-link {
              background-color: inherit;

              span {
                color: $white;
              }

              .badge,
              svg {
                color: $white !important;
                stroke: $white !important;
              }
            }
          }
        }
      }
    }

    // nav-tabs
    .nav-tabs,
    .nav-tabs.nav-justified,
    .nav:not(.nav-pills) {
      .nav-item {
        .nav-link {
          background-color: $dark-card-color;

          &.active {
            background-color: $dark-card-color;
          }

          &.disabled {
            opacity: 0.5;
          }
        }
      }

      &~.tab-content {
        .tab-pane {
          background-color: $dark-card-color;
        }
      }
    }

    // vertical nav tabs
    .nav-vertical {
      .nav.nav-tabs {

        &.nav-left,
        &.nav-right {
          &~.tab-content {
            .tab-pane {
              background-color: $dark-card-color;
            }
          }
        }
      }
    }

    // nav pills
    .nav-pills {
      .nav-item {
        .nav-link {
          &.active {
            color: $white;
          }
        }
      }

      &.nav-active-bordered-pill {
        .nav-item {
          .nav-link.active {
            background-color: transparent !important;
          }
        }
      }
    }

    // Active Item in nav tabs justified
    .nav-justified {
      &.nav.nav-tabs {
        .nav-item .nav-link {
          &.active {
            background-color: transparent;
          }
        }
      }
    }

    // card
    .card {
      background-color: $dark-card-color;

      &.border {
        border-color: $theme-dark-border-color !important;
      }

      .card-title {
        color: $white;
      }

      .card-header,
      .card-footer {
        color: $theme-dark-text-color;
        background-color: $dark-card-color !important;
      }

      .card-body,
      .card-footer {
        color: $theme-dark-text-color;
        border-color: $theme-dark-border-color;
      }

      &.overlay-img-card {
        .card-img-overlay {

          span,
          p {
            color: $white;
          }
        }
      }
    }

    // media
    .media-bordered {
      .media {
        border-color: $theme-dark-border-color;
      }
    }

    // activity timeline
    .activity-timeline {
      border-color: $theme-dark-border-color;
    }

    // analytics decor card
    #dashboard-analytics {
      .bg-analytics {
        background: linear-gradient(118deg,
            rgba($primary, 1),
            rgba($primary, 0.7));

        p {
          color: $white;
        }

        .card-body {
          background-color: transparent;
        }
      }
    }

    // Dashboard ecommerce + Advance Card
    .chat-widget {
      &.chat-application {
        .chat-app-window {
          .user-chats {

            // Chat area css
            .chats {
              .chat-body {
                .chat-content {
                  p {
                    color: $white;
                  }
                }
              }

              .chat-left {
                .chat-content {
                  background-color: $dark-chat-bg-color;
                  border: 1px solid $theme-dark-border-color;

                  p {
                    color: $theme-dark-text-color;
                  }
                }
              }
            }
          }

          .chat-footer {
            border-top: 1px solid $theme-dark-border-color;

            .card-body {
              padding-top: 1.5rem !important;
            }
          }
        }
      }
    }

    //badge
    .badge {
      color: $white;

      span {
        color: $white;
      }

      &:hover {
        i {
          color: $white;
        }
      }
    }

    // popover
    .popover {
      &[x-placement="top"] {
        .arrow {
          &:after {
            border-top-color: $content-dark-bg;
          }
        }
      }

      &[x-placement="bottom"] {
        .arrow {
          &:after {
            border-bottom-color: $primary;
          }
        }
      }

      &[x-placement="left"] {
        .arrow {
          &:after {
            border-left-color: $content-dark-bg;
          }
        }
      }

      &[x-placement="right"] {
        .arrow {
          &:after {
            border-right-color: $content-dark-bg;
          }
        }
      }

      .popover-body {
        background-color: $content-dark-bg;
        color: $theme-dark-text-color;
      }
    }

    // toast
    .toast {
      background-color: $content-dark-bg;
      box-shadow: -5px 5px 5px 0px rgba($pure-black, 0.3);

      .toast-header {
        background-color: $content-dark-bg;
        color: $theme-dark-text-color;
        border-color: $theme-dark-border-color;

        .close {
          text-shadow: none;
          opacity: 1;
        }
      }

      .toast-body {
        background-color: $content-dark-bg;
        color: $theme-dark-text-color;
      }
    }

    // Progress
    .progress {
      background-color: $content-dark-bg;
    }

    // avatar
    .avatar {
      background-color: $content-dark-bg;

      .avatar-content {
        color: $white;
      }
    }

    // chip
    .chip {
      background-color: $content-dark-bg;

      .avatar {
        background-color: $dark-card-color;
      }

      .chip-body {

        .chip-text,
        span {
          color: $white;
        }
      }
    }

    // divider
    .divider {
      .divider-text {
        background-color: transparent;
        color: $theme-dark-text-color;

        &::before,
        &::after {
          border-color: $theme-dark-border-color;
        }
      }

      &.divider-dark {
        .divider-text {

          &::before,
          &::after {
            border-color: $gray-700 !important;
          }
        }
      }
    }

    // Form Wizard
    .vx-wizard.nav.nav-tabs {
      .step-wrapper.nav-item {
        .step.nav-link:not(.active) {
          .step-text {
            background-color: $content-dark-bg;

            svg {
              color: $theme-dark-text-color;
            }
          }
        }
      }
    }

    // list group
    .list-group {
      .list-group-item {

        &:not([class*="list-group-item-"]),
        &.list-group-item-action {
          background-color: $dark-card-color;
          border-color: $theme-dark-border-color;
          color: $theme-dark-text-color;

          &:hover {
            background-color: $content-dark-bg;
          }
        }

        &.active {
          background-color: $primary;
          color: $white;

          p,
          small {
            color: $white;
          }
        }

        &.disabled {
          background-color: $content-dark-bg;
        }
      }
    }

    /********** FORMS **********/

    // input
    input.form-control,
    .custom-file-label,
    textarea.form-control {
      background-color: $content-dark-bg;
      color: $theme-dark-text-color;

      &:not(:focus) {
        border: 0;
      }

      &::placeholder {
        color: $theme-dark-text-color;
      }

      &~.form-control-position {
        svg {
          color: $theme-dark-text-color;
        }
      }

      &:focus {
        &~.form-control-position {
          i {
            color: $primary;
          }
        }
      }

      &:disabled,
      &[readonly] {
        opacity: 0.5;
      }
    }

    // text area counter
    .char-textarea {
      &.active {
        color: $theme-dark-text-color !important;
      }

      &.max-limit {
        color: $danger !important;
      }
    }

    // file uploader default
    .custom-file-label {
      &:after {
        background-color: $content-dark-bg;
        border-left: 1px solid $theme-dark-border-color;
        color: $theme-dark-text-color;
      }
    }

    // form group icons
    .has-icon-left {
      &.input-divider-left {
        .form-control-position {
          i {
            border-right-color: $theme-dark-border-color;
          }
        }
      }

      &.input-divider-right {
        .form-control-position {
          i {
            border-left-color: $theme-dark-border-color;
          }
        }
      }
    }

    // select
    select.form-control,
    .custom-select {
      background-color: $content-dark-bg;
      color: $theme-dark-text-color;
      border: 0;

      option:checked {
        background-color: $dark-card-color;
      }
    }

    // Custom Input Checkbox/Radio
    .custom-control {

      &.custom-radio,
      &.custom-checkbox {
        input[disabled] {
          &~label {

            &:before,
            &:after {
              opacity: 0.5;
            }
          }
        }
      }
    }

    // React Select
    .select__control,
    .React__control {
      background-color: $content-dark-bg;
      border-color: $theme-dark-border-color;

      .select__value-container,
      .React__value-container {

        .select__single-value,
        .React__single-value {
          color: $theme-dark-text-color;
        }

        .select__multi-value {
          background-color: $dark-card-color;

          .select__multi-value__label {
            color: $theme-dark-text-color;
          }
        }
      }

      &~ {

        .select__menu,
        .React__menu {
          background-color: $content-dark-bg;

          .select__menu-list,
          .React__menu-list {

            &.select__option--is-selected,
            &.React__option--is-selected {
              background-color: $dark-card-color;
            }
          }
        }
      }

      &.select__control--is-focused {
        .select__loading-indicator {
          color: $theme-dark-text-color;

          span {
            background-color: $theme-dark-text-color;
          }
        }
      }
    }

    // React Number Input
    .react-numeric-input {
      background-color: $content-dark-bg !important;

      input {
        background-color: $content-dark-bg !important;
        color: $theme-dark-text-color !important;
      }
    }

    // React Toggle
    .react-toggle-wrapper {
      .react-toggle {
        &:not(.react-toggle--checked):not([class*="switch-"]) {
          .react-toggle-track {
            background-color: $content-dark-bg;
          }
        }

        .react-toggle-track {

          .react-toggle-track-x,
          .react-toggle-track-check {
            svg {
              stroke: $white;
              fill: $white;
            }
          }
        }
      }
    }

    // switch
    .custom-switch {
      .custom-control-input[disabled] {
        &~.custom-control-label {
          opacity: 0.5;
        }
      }

      .custom-control-input {
        &:not(:disabled):active {
          &~.custom-control-label::before {
            background-color: $primary;
          }
        }

        &:checked~.custom-control-label::before {
          background-color: $primary;
        }
      }

      .custom-control-label {
        &:before {
          background-color: $theme-dark-border-color;
        }
      }
    }

    // input groups
    .input-group {

      .input-group-prepend,
      .input-group-append {
        .input-group-text {
          background-color: $dark-card-color;
          border: 1px solid $theme-dark-border-color;
        }
      }

      .input-group-prepend {
        &~.form-control {
          border-left: 1px solid $theme-dark-border-color;
        }
      }

      .form-control {
        &~.input-group-append {
          border-left: 1px solid $theme-dark-border-color;
        }
      }
    }

    // floating label
    .form-label-group {

      &>input:not(:focus):not(:placeholder-shown)~label,
      &>textarea:not(:focus):not(:placeholder-shown)~label {
        color: $theme-dark-text-color !important;
      }
    }

    // Datepicker
    .form-control.flatpickr-input {
      background-color: $content-dark-bg;

      &~input {
        background-color: $content-dark-bg;
      }
    }

    .flatpickr-calendar {
      &:not(.inline) {

        &:after,
        &:before {
          border-bottom-color: $primary;
        }
      }

      .flatpickr-innerContainer {
        background: $content-dark-bg;
        border-color: $content-dark-bg;
      }

      .flatpickr-months {

        .flatpickr-prev-month,
        .flatpickr-next-month {
          fill: $white;
          color: $white;
        }

        .flatpickr-month {
          select:hover {
            background: $white;
          }
        }

        .numInputWrapper {
          .numInput {
            color: $white;
          }
        }
      }

      .flatpickr-days {
        border-color: $content-dark-bg;

        .flatpickr-day {

          &:hover,
          &:focus {
            background-color: $dark-card-color;
            border-color: $dark-card-color;
          }

          &.prevMonthDay,
          &.nextMonthDay,
          &.flatpickr-disabled {
            color: $gray-400;
          }
        }
      }

      .flatpickr-weekdays,
      .flatpickr-weekday {
        color: $white;
      }
    }

    /********** TABLE **********/

    // table
    .table {
      &:not(.table-inverse) {
        background-color: $theme-dark-table-bg;

        .thead,
        tbody {
          tr:not([class*="table-"]) {

            th,
            td {
              border-color: $theme-dark-border-color;
              color: $theme-dark-text-color;

              code {
                background-color: $dark-card-color;
              }
            }
          }
        }

        thead {
          tr {
            th {
              border: 0;
              background-color: $theme-dark-table-bg;
              color: $theme-dark-text-color;
            }
          }
        }

        tbody {
          tr {

            // for contextual classes
            &[class*="table-"] {

              td,
              th {
                background-color: unset;
                color: $black;
                border-color: $theme-dark-border-color;
              }
            }

            &.table-dark {

              td,
              th {
                color: $white;
              }
            }

            &.table-active {

              td,
              th {
                color: $theme-dark-text-color;
              }
            }

            th {
              background-color: $content-dark-bg;
            }

            td {
              background-color: $content-dark-bg;
              color: $theme-dark-text-color;
            }
          }
        }

        // bordered table
        &.table-bordered {
          border: 1px solid $theme-dark-border-color;

          thead,
          tbody {
            tr {

              th,
              td {
                border: 1px solid $theme-dark-border-color;
              }
            }
          }
        }

        // table hover effect

        &.table-hover {
          tbody {
            tr {
              &:hover {
                background-color: $dark-card-color;
              }

              th,
              td {
                background-color: unset;
              }
            }
          }
        }

        // table striped
        &.table-striped {
          tbody {
            tr {
              &:nth-of-type(odd) {
                background-color: $dark-card-color;
              }

              td,
              th {
                background-color: unset;
              }
            }
          }
        }

        // for table in content section

        &.table-mx-0 {
          background-color: $dark-card-color;

          thead,
          tbody {
            tr {

              th,
              td {
                background-color: $dark-card-color;
              }
            }
          }
        }
      }
    }

    // React datatable
    .ReactTable {
      .rt-table {
        .rt-thead {
          border-color: $theme-dark-border-color;

          .rt-th {
            background-color: $content-dark-bg;
            border-color: $theme-dark-border-color;

            input,
            select {
              background-color: $dark-card-color;
              color: $theme-dark-text-color;
            }
          }
        }

        .rt-tbody {
          .rt-tr {
            .rt-td {
              background-color: $content-dark-bg;
              border-color: $theme-dark-border-color;

              div:not(.rt-expander) {
                background-color: $content-dark-bg !important;
              }

              &.rt-expandable {
                background-color: $content-dark-bg !important;

                .rt-expander {
                  &:after {
                    border-top-color: $theme-dark-text-color;
                  }
                }
              }
            }
          }

          .rt-tr-group {
            border-color: $theme-dark-border-color;
          }
        }
      }

      .pagination-bottom {
        .-pageJump {
          input {
            background-color: $content-dark-bg;
            color: $theme-dark-text-color;
          }
        }

        .select-wrap {
          select {
            background-color: $content-dark-bg;
            color: $theme-dark-text-color;
          }
        }
      }

      &.nested-table {
        .rt-tbody {
          .rt-tr-group {
            .rt-td:first-child {
              background-color: $content-dark-bg !important;
            }
          }
        }
      }
    }

    // AG Grid
    .ag-grid-table {
      background-color: $dark-card-color;

      .ag-dropdown {
        border-color: $theme-dark-border-color;

        div {
          color: $theme-dark-text-color;
        }
      }

      .ag-pinned-left-header {
        border-color: $theme-dark-border-color;
      }

      .ag-root-wrapper {
        .ag-root-wrapper-body {
          .ag-header {
            background-color: $dark-card-color;
            border-color: $theme-dark-border-color;

            .ag-header-cell {
              border-color: $theme-dark-border-color;
              background-color: $dark-card-color;
            }

            .ag-header-cell-label,
            .ag-floating-filter-input {
              color: $theme-dark-text-color;
            }

            .ag-floating-filter-input {
              border-color: $theme-dark-border-color;
            }
          }

          .ag-body-viewport {
            .ag-cell {
              color: $theme-dark-text-color;
              border-color: $theme-dark-border-color;
            }

            .ag-row {
              border-color: $theme-dark-border-color;
            }
          }
        }
      }

      .ag-row-hover,
      .ag-header-cell:hover,
      .ag-row-selected {
        background-color: $content-dark-bg !important;
      }

      .ag-icon {
        color: $theme-dark-text-color;

        &.ag-icon-checkbox-checked {
          color: $primary;
        }
      }

      .ag-paging-panel {
        border-color: $theme-dark-border-color;

        .ag-paging-button {
          &.ag-disabled {
            background-color: #373f6d;

            span {
              color: $white !important;
            }
          }
        }

        span[ref="lbTotal"] {
          background-color: #373f6d;
          color: $theme-dark-text-color !important;
        }
      }

      .ag-menu {
        background-color: $content-dark-bg;

        .ag-filter-select {
          border-color: $theme-dark-border-color;
          color: $theme-dark-text-color;

          option {
            color: $theme-dark-text-color;
            background-color: $content-dark-bg;
          }
        }

        .ag-input-wrapper {
          .ag-filter-filter {
            color: $theme-dark-text-color;
            border-color: $theme-dark-border-color;

            &::placeholder {
              color: $theme-dark-text-color;
            }
          }
        }
      }

      ::-webkit-scrollbar-thumb {
        background: $primary;
      }

      ::-webkit-scrollbar-track {
        background: $content-dark-bg;
      }
    }

    header.hlidmt {
      background-color: $dark-card-color;
    }

    // Datatable
    .rdt_Table {
      .rdt_TableHead {
        .rdt_TableHeadRow {
          background-color: $theme-dark-table-bg;

          .rdt_TableCol,
          .rdt_TableCol_Sortable {
            color: $theme-dark-text-color !important;
          }
        }
      }

      .rdt_TableBody {

        .rdt_TableRow,
        .rdt_ExpanderRow {
          background-color: $content-dark-bg;
          color: $theme-dark-text-color;

          svg {
            color: $theme-dark-text-color;
          }
        }
      }

      &~.rdt_Pagination {
        background-color: $dark-card-color;

        select {
          background-color: $white;
        }

        #pagination-first-page,
        #pagination-previous-page,
        #pagination-next-page,
        #pagination-last-page {
          background-color: #373f6d;

          svg {
            fill: $theme-dark-text-color;
          }
        }
      }
    }

    // Sweet Alert
    .sweet-alert {
      background-color: $dark-card-color !important;

      div {
        background: $dark-card-color !important;
      }

      span {
        z-index: 5 !important;
      }

      h2,
      p {
        color: $theme-dark-text-color !important;
      }
    }

    /********** PAGES **********/

    // authentication
    .bg-authentication {
      background-color: $blank-bg-color;

      .auth-title {
        color: $theme-dark-text-color;
      }

      .form-group.row,
      .card-footer {
        background-color: $dark-card-color;

        .btn-facebook,
        .btn-twitter {
          span {
            color: $white;
          }
        }
      }

      .form-group {
        .vx-checkbox-con {
          span {
            color: $theme-dark-text-color;
          }
        }
      }
    }

    // coming soon
    .getting-started {
      .clockCard {
        p {
          color: $theme-dark-text-color !important;
        }
      }
    }

    // Error Pages
    .auth-card {
      h1 {
        color: $headings-color;
      }

      p {
        color: $white;
      }
    }

    // user profile
    #user-profile {
      .profile-header {
        img {
          border-color: $white !important;
        }

        .profile-header-nav {
          background-color: $dark-card-color;
        }

        .navbar {
          svg {
            color: $theme-dark-text-color;
          }
        }
      }

      .btn-load.loading {
        background-color: rgba($white, 0.75) !important;
      }
    }

    // Account Settings
    .account-settings-tab {
      .nav-link {
        background: transparent !important;
      }
    }

    // Search Page
    #search-website {

      // search page
      .search-bar {
        .form-control {
          background-color: $dark-card-color;
        }
      }

      // search filters
      .search-menu {
        .search-filter {
          background-color: $dark-card-color;
          color: $theme-dark-text-color;
        }
      }

      // search results
      .search-result-info {
        .dropdown-toggle {
          color: $theme-dark-text-color;
        }
      }

      // search pagination
      .search-pagination {
        .pagination {
          .page-item {
            background-color: $dark-card-color;

            &.active {
              .page-link {
                background-color: $primary;
              }
            }

            .page-link {
              background-color: $dark-card-color;
            }
          }
        }
      }
    }

    // FAQ
    .faq {
      .accordion {
        .collapse-margin {
          background-color: $dark-card-color;
        }
      }
    }

    // knowledge base and faq card text color
    .faq-bg,
    .knowledge-base-bg {
      .card-body {
        .card-text {
          color: $white;
        }
      }
    }

    // knowledge base card
    .search-content {
      .card {
        .card-body {
          .text-dark {
            color: $theme-dark-text-color !important;
          }
        }
      }
    }

    // knowledge base category and knowledge base question
    .knowledge-base-category,
    .knowledge-base-question {
      .list-group {
        .list-group-item {
          color: $primary;
        }
      }
    }

    // invoice page
    .invoice-items-table,
    .invoice-total-table {
      .table-borderless {
        border: 0;
      }
    }

    .invoice-header {
      .input-group {
        .form-control {
          background-color: $dark-card-color;
        }
      }
    }

    /********** CHARTS **********/

    // Apex Charts
    .apexcharts-canvas {
      .apexcharts-gridlines-horizontal {
        .apexcharts-gridline {
          stroke: $theme-dark-border-color;
        }
      }

      // chart tooltip
      .apexcharts-tooltip.light,
      .apexcharts-yaxistooltip,
      .apexcharts-xaxistooltip,
      .apexcharts-tooltip {
        background-color: $theme-dark-table-bg;
        border-color: $content-dark-bg;

        .apexcharts-tooltip-title,
        .apexcharts-tooltip-text {
          background-color: $theme-dark-table-bg;
          color: $theme-dark-text-color;
        }
      }

      .apexcharts-tooltip.dark {
        .apexcharts-tooltip-text {

          .apexcharts-tooltip-text-label,
          .apexcharts-tooltip-text-value {
            color: $white;
          }
        }
      }

      .apexcharts-xaxistooltip-bottom {
        &:before {
          border-bottom-color: transparent;
        }

        &:after {
          border-bottom-color: $content-dark-bg;
        }
      }

      .apexcharts-yaxistooltip-left {
        &:before {
          border-left-color: transparent;
        }

        &:after {
          border-left-color: $content-dark-bg;
        }
      }

      // chart text
      text {
        fill: $white !important;
      }

      // chart legend
      .apexcharts-legend-series {
        .apexcharts-legend-text {
          color: $theme-dark-text-color !important;
        }
      }

      // support chart
      .apexcharts-radialbar-track {
        path {
          stroke: $content-dark-bg;
        }
      }

      .apexcharts-inner {

        // polygon sales chart
        polygon {
          stroke: $theme-dark-table-bg !important;
          fill: $content-dark-bg !important;
        }

        rect {
          fill: $dark-card-color;
        }
      }

      // apex charts pie series
      .apexcharts-pie-series {
        path {
          stroke: $content-dark-bg;
        }
      }

      // apex chart menu
      .apexcharts-menu {
        background-color: $content-dark-bg;
        border: 0;
      }

      .apexcharts-toolbar {

        .apexcharts-zoom-in-icon,
        .apexcharts-zoom-out-icon,
        .apexcharts-zoom-icon,
        .apexcharts-menu-icon,
        .apexcharts-reset-zoom-icon {
          &:hover {
            svg {
              fill: $theme-dark-text-color;
            }
          }
        }

        .apexcharts-menu-item {
          color: $theme-dark-text-color;

          &:hover {
            background-color: $dark-card-color;
          }
        }
      }
    }

    // Recharts
    .recharts-wrapper {
      line {
        stroke: $theme-dark-text-color;
      }

      text {
        fill: $white;
      }

      .recharts-default-tooltip {
        background-color: $dark-card-color !important;
        border-color: $theme-dark-border-color !important;
        color: $theme-dark-text-color;
      }

      .recharts-rectangle.recharts-tooltip-cursor {
        fill: $content-dark-bg !important;
      }

      .recharts-pie-sector {
        .recharts-sector {
          stroke: $theme-dark-border-color;
        }
      }

      .recharts-radial-bar-background {
        .recharts-radial-bar-background-sector {
          fill: $content-dark-bg;
        }
      }
    }

    // Dropdown Divider
    .dropdown-divider {
      border-color: $theme-dark-border-color;
    }

    /********** APPLICATIONS **********/

    // email application
    .email-application {

      // search and sidebar
      .email-app-sidebar {
        background-color: $dark-card-color;
        border-color: $theme-dark-border-color;
      }

      .app-fixed-search {
        background-color: $dark-card-color;
        border-color: $theme-dark-border-color;

        svg {
          color: $theme-dark-text-color;
        }

        .form-control {
          color: $theme-dark-text-color;

          &::placeholder {
            color: $theme-dark-text-color;
          }

          &:focus {
            &~.form-control-position {
              svg {
                color: $primary;
              }
            }
          }
        }

        .form-control-position {
          svg {
            color: $theme-dark-text-color;
          }
        }
      }

      .email-app-list-wrapper {
        border-color: $theme-dark-border-color;

        .app-action {
          border-color: $theme-dark-border-color;
        }
      }

      // for app-menu text color
      .email-app-menu {
        .sidebar-menu-list {

          .list-group-messages,
          .list-group-labels {
            .list-group-item {
              background: $dark-card-color;

              &.active,
              &.active:hover {
                color: $primary;
              }

              &:hover {
                color: $theme-dark-text-color;
              }
            }
          }
        }
      }

      // Content Right
      .content-right {
        border-color: $theme-dark-border-color;

        // mails
        .email-app-list {
          .app-action {
            .action-right {
              .list-inline-item {
                svg {
                  color: $theme-dark-text-color;
                }
              }
            }
          }

          .email-user-list {
            .media {
              border-color: $theme-dark-border-color;

              &:hover {
                box-shadow: 0 0 0 0 $gray-800;
              }
            }

            .mail-read,
            .media {
              background-color: $content-dark-bg;

              .user-details,
              .mail-message {

                p,
                .list-group-item-text {
                  color: $theme-dark-text-color;
                }

                .mail-meta {
                  .mail-date {
                    color: $theme-dark-text-color;
                  }
                }
              }
            }

            .mail-unread {
              background-color: $dark-card-color;
            }
          }
        }
      }

      // app email details
      .email-app-details {
        border-color: $theme-dark-border-color;

        &.show {
          background-color: $theme-dark-table-bg;
        }

        .email-detail-header {
          border-color: $theme-dark-border-color;
          background-color: $dark-card-color;

          .email-header-right {
            .list-inline-item {
              .dropdown-toggle {
                color: $theme-dark-text-color;
              }
            }
          }

          svg {
            color: $theme-dark-text-color;
          }
        }

        .mail-message-wrapper,
        .email-detail-head {
          border-color: $theme-dark-border-color !important;
        }
      }
    }

    // chat application
    .chat-application {
      .chat-profile-sidebar {
        background-color: $content-dark-bg;
      }

      .sidebar-content {
        background-color: $dark-card-color;
        border-color: $theme-dark-border-color;

        .chat-fixed-search {
          border-color: $theme-dark-border-color;

          .form-control {
            background-color: $dark-card-color;

            &:not(:focus) {
              border: 1px solid $theme-dark-border-color;
            }
          }

          svg {
            color: $theme-dark-text-color;
          }
        }

        .chat-user-list {
          .chat-users-list-wrapper {
            li {
              border-color: $theme-dark-border-color;

              &:not(.active):hover {
                background: $dark-card-color;
              }

              &.active {
                .contact-info {
                  p {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }

      .content-right {
        border-color: $theme-dark-border-color;

        .avatar {
          border: 2px solid $white;
        }

        .chat-app-window {
          .start-chat-area {
            background-color: $dark-chat-bg-color;

            .start-chat-icon,
            .start-chat-text {
              background: $content-dark-bg;
            }
          }

          .active-chat {
            .chat_navbar {
              .chat_header {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);

                svg {
                  color: $theme-dark-text-color;
                }
              }
            }

            .user-chats {
              background-color: $dark-chat-bg-color;

              .chat-left {
                .chat-content {
                  color: $theme-dark-text-color;
                  background-color: $dark-card-color;
                  border: 1px solid $theme-dark-text-color;

                  p {
                    color: $theme-dark-text-color;
                  }
                }
              }

              .chat-content {
                p {
                  color: $white;
                }
              }

              .chat-content {
                background-color: $content-dark-bg;
              }
            }

            .chat-app-form {
              background-color: $dark-card-color;

              .form-control {
                background-color: $dark-card-color;
                border: 1px solid $theme-dark-border-color;
              }
            }
          }
        }
      }

      .chat-profile-sidebar {
        border: 1px solid !important;
      }

      // User Sidebar (LEFT SIDEBAR) AND  Right Sidebar
      .user-profile-sidebar,
      .chat-profile-sidebar {
        background-color: $dark-card-color;
        border-color: $theme-dark-border-color !important;

        .user-profile-header,
        .chat-profile-header {
          border-color: $theme-dark-border-color;

          .avatar {
            border: 2px solid $white;
          }

          .close-icon {
            color: $theme-dark-text-color;
          }
        }
      }
    }

    // todo-application
    .todo-application {
      border-color: $theme-dark-border-color;

      // Main Sidebar
      .todo-sidebar {
        background-color: $dark-card-color;
        border-color: $theme-dark-border-color;

        .list-group {
          .list-group-item {
            &:hover {
              color: $theme-dark-text-color;

              svg {
                color: $theme-dark-text-color;
              }
            }
          }
        }
      }

      // Todo List
      .todo-app-area {
        .todo-app-list-wrapper {
          border-color: $theme-dark-border-color;
        }

        .app-fixed-search {
          background-color: $dark-card-color;
          border-color: $theme-dark-border-color !important;

          svg {
            color: $theme-dark-text-color;
          }

          .form-control-position {
            svg {
              color: $theme-dark-text-color;
            }
          }
        }

        .todo-task-list {
          .todo-item {
            border-color: $theme-dark-border-color;

            &:hover {
              box-shadow: none;
            }

            .chip {
              background-color: $dark-card-color;
            }

            .todo-item-action {

              .todo-item-favorite,
              .todo-item-delete,
              .todo-item-info {
                svg {
                  color: $theme-dark-text-color;
                }
              }
            }
          }
        }
      }

      // Add Task Sidebar
      .task-sidebar {
        background-color: $dark-card-color;
      }

      .task-header {
        background-color: $dark-card-color;

        .close-icon {
          color: $theme-dark-text-color;
        }
      }

      .task-body {
        svg {
          color: $theme-dark-text-color;
        }
      }
    }

    // ecommerce application
    .ecommerce-application {

      // sidebar
      .sidebar-shop {
        .filter-heading {
          color: $theme-dark-text-color;
        }

        .price-slider {
          .range-slider {
            background-color: $content-dark-bg;
          }
        }

        .ratings-list {

          li,
          &~.stars-received {
            color: $theme-dark-text-color;
          }
        }
      }

      // main content
      // header items
      .ecommerce-header-items {
        .result-toggler {
          .search-results {
            color: $theme-dark-text-color;
          }

          .shop-sidebar-toggler {
            svg {
              color: $theme-dark-text-color;
            }
          }
        }

        .view-options {
          .React-Select {
            .select__control {
              background-color: $dark-card-color;

              .select__single-value {
                color: $theme-dark-text-color;
              }
            }
          }

          .select__menu {
            .select__option {
              color: $theme-dark-text-color;

              &:hover {
                color: $white;
              }

              &.select__option--is-selected {
                background-color: $primary;
              }
            }
          }

          // change view btn
          .view-btn-option {
            .view-btn {
              background-color: $dark-card-color !important;

              &:not(.active) {
                svg {
                  color: $theme-dark-text-color;
                }
              }
            }
          }
        }
      }

      // product search
      .search-product {
        background-color: $dark-card-color;

        &~.form-control-position {
          svg {
            color: $theme-dark-text-color;
          }
        }
      }

      // list and grid view
      .grid-view,
      .list-view {
        .ecommerce-card {
          .card-body {
            border-color: $theme-dark-border-color;
          }

          .item-img {
            background-color: $white;
          }

          .item-name {
            color: $white;
          }

          .item-options {
            .wishlist {
              span {
                color: $headings-color;
              }
            }

            .cart {
              span {
                color: $white;
              }
            }
          }
        }
      }

      // pagination
      .pagination {
        .page-item {
          background-color: $dark-card-color;

          &.active {
            background-color: $dark-card-color;

            .page-link {
              background-color: $primary;
            }
          }

          .page-link {
            background-color: $dark-card-color;
          }
        }
      }

      // wishlist-items
      .wishlist-items {
        .ecommerce-card {
          .move-cart {
            .move-to-cart {
              color: $white;
            }
          }
        }
      }

      // checkout
      .product-checkout {
        .checkout-options {
          .detail-total {
            color: $theme-dark-text-color;
          }
        }
      }

      .product-checkout.list-view {
        background: $content-dark-bg;
      }
    }

    .app-ecommerce-details {
      .item-features {
        background-color: $dark-chat-bg-color;
      }

      .swiper-slide {
        background-color: $content-dark-bg;
      }

      .swiper-button-next,
      .swiper-button-prev {
        color: $primary;
      }
    }

    // calendar
    .app-calendar {
      .rbc-calendar {

        .rbc-month-view,
        .rbc-month-row,
        .rbc-day-bg,
        .rbc-header,
        .rbc-time-view,
        .rbc-time-content,
        .rbc-time-header-content,
        .rbc-events-container,
        .rbc-timeslot-group,
        .rbc-time-slot {
          border-color: $theme-dark-border-color !important;
        }

        .rbc-date-cell.rbc-off-range {
          color: $theme-dark-text-color;
        }

        .rbc-today {
          background-color: transparent;
        }
      }

      .add-event-sidebar,
      .add-event-sidebar .header {
        background-color: $dark-card-color;

        svg {
          color: $theme-dark-text-color;
        }
      }
    }

    // app file manager
    .file-manager-application {
      border-color: $theme-dark-border-color;

      .sidebar-file-manager {
        background-color: $dark-card-color;

        .list-group-item-action span {
          color: inherit;
        }

        .storage-status {
          svg {
            color: $theme-dark-text-color;
          }
        }
      }

      .file-manager-main-content {
        border-color: $theme-dark-border-color;

        .bottom-content {
          .content-logo {
            background-color: $dark-card-color;
          }
        }

        .file-manager-app-searchbar {
          background-color: $content-dark-bg;
          border-color: $theme-dark-border-color;
        }
      }
    }

    // Invoice Application
    .invoice-list-wrapper {
      .dataTables_wrapper {

        .invoice-list-dataTable-header,
        .rdt_Table,
        .rdt_TableRow {
          background-color: $dark-card-color;
          border-color: $theme-dark-border-color !important;
        }

        .dHCTRW.rdt_TableRow {
          background-color: $theme-dark-border-color !important;
        }

        .icdHOq {
          background-color: $dark-card-color;
          color: $theme-dark-text-color;
        }

        .action-btns button {
          color: $theme-dark-text-color;
        }

        .vx-pagination li:hover span {
          color: inherit;
        }
      }
    }

    // app user list
    .app-user-list {
      .ag-grid-table {
        .ag-header {
          .ag-header-cell {
            background-color: transparent;
          }
        }
      }
    }

    /********** EXTENSIONS **********/

    // RC slider
    .rc-slider {
      .rc-slider-rail {
        background-color: $content-dark-bg !important;
      }

      &.rc-slider-disabled {
        background-color: $content-dark-bg;
        opacity: 0.7;
      }
    }

    // dropzone
    .dropzone {
      background-color: $content-dark-bg;

      &~.thumb-container {
        .dz-thumb {
          border-color: $theme-dark-border-color !important;
        }
      }
    }

    // Wysiwyg Editor
    .rdw-editor-wrapper {
      .rdw-editor-toolbar {
        background-color: $content-dark-bg;
        border-color: $theme-dark-border-color;

        // .rdw-option-wrapper {
        //   background-color: transparent;
        // }
        .rdw-dropdown-wrapper {
          background-color: $dark-card-color;
          border-color: $theme-dark-border-color;

          &:hover {
            background-color: $dark-card-color;
            box-shadow: none;
          }

          .rdw-dropdown-carettoopen {
            border-top-color: $theme-dark-text-color;
          }

          .rdw-dropdown-carettoclose {
            border-bottom-color: $theme-dark-text-color;
          }

          .rdw-dropdown-optionwrapper {
            border-color: $theme-dark-border-color;
            background-color: $dark-card-color;

            .rdw-dropdownoption-active,
            .rdw-dropdownoption-highlighted {
              background-color: $content-dark-bg;
            }
          }
        }
      }
    }

    // Extensions Pagination
    .vx-pagination {
      li {
        background-color: $content-dark-bg;

        a {
          color: $theme-dark-text-color;
        }
      }
    }

    // React Contexify
    .react-contexify {
      background-color: $content-dark-bg;
      box-shadow: none;

      .react-contexify__item {
        border-color: $theme-dark-border-color;

        .react-contexify__item__content {
          color: $theme-dark-text-color;
        }
      }
    }

    // Swiper
    .swiper-container {
      .swiper-slide.swiper-slide-active {

        span,
        svg {
          color: $white;
        }
      }

      &.centered-style-1 {
        .swiper-slide {
          background-color: $dark-chat-bg-color;
        }
      }

      &.centered-style-2 {
        .swiper-slide:not(.swiper-slide-active) {
          background-color: $dark-chat-bg-color;
        }
      }

      &.swiper-parallax {

        .swiper-slide,
        .swiper-slide p {
          color: $black;
        }
      }
    }

    // Vuexy Search
    .vx-autocomplete-container {
      .suggestions-list {
        background-color: $content-dark-bg;

        .suggestion-item {

          &.active,
          &.active:hover {
            background-color: $dark-card-color;
          }

          &:hover {
            background-color: $dark-card-color !important;
          }

          svg:not([class*="text-"]) {
            stroke: $theme-dark-text-color;
          }
        }
      }
    }

    /********** CUSTOMIZER **********/

    .customizer {
      background-color: $content-dark-bg;

      .close-icon {
        svg {
          color: $theme-dark-text-color;
        }
      }

      .bg-default {
        background-color: $dark-card-color;
        border-color: $dark-card-color !important;
      }
    }

    .vertical-layout.fixed-footer {
      .footer {
        background-color: $dark-card-color;
      }
    }

    // svg color section in darklayout in pricing.html
    .plan-svg,
    .common-svg,
    .highlighted-svg {
      fill: $theme-dark-text-color !important;
    }

    // blog sidebar categories list
    .blog-wrapper {
      .categories li a {
        color: $theme-dark-text-color;

        &:hover {
          color: $primary;
        }
      }
    }

    // blog details social media icons
    .blog-details-social li a {
      color: $theme-dark-text-color;
    }

    // blog details comment card
    .comment-card {
      background-color: $dark-card-color !important;
    }



    /********** HORIZONTAL Layout **********/
    .horizontal-layout {
      .horizontal-menu-wrapper {
        background: linear-gradient(to bottom,
            rgba(37, 43, 71, 0.76) 44%,
            rgba(56, 53, 53, 0.46) 73%,
            rgba(255, 255, 255, 0) 100%);
      }

      .header-navbar {
        background-color: $dark-card-color !important;

        #main-menu-navigation {
          .dropdown-item {
            .item-content {
              color: $theme-dark-text-color !important;
            }

            &.active {
              background-color: $dark-card-color !important;
            }

            &:not(.active):hover {
              span {
                color: $theme-dark-text-color;
              }
            }

            &.has-sub.hover,
            &.has-sub.hover .dropdown {
              background-color: transparent !important;
            }
          }

          .nav-item {
            &.hover:not(.active) {

              .dropdown,
              a.nav-link {
                background-color: $content-dark-bg !important;
              }
            }

            &.active {
              .menu-icon svg {
                stroke: $white;
              }

              .dropdown-menu {

                li.active,
                li.active:hover {
                  background: $dark-card-color !important;
                }

                .dropdown-item:hover {
                  background: $dark-card-color !important;
                }
              }
            }
          }

          .dropdown-menu {
            &::-webkit-scrollbar-thumb {
              background: $dark-card-color !important;
            }

            &::-webkit-scrollbar-track {
              background: $content-dark-bg !important;
            }
          }
        }
      }

      &.fixed-footer {
        .footer {
          background: $dark-card-color;
        }
      }
    }
  }
}
