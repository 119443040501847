.primary-color {
  color: #068f97 !important;
}
.underline {
  border-bottom: 1px solid gainsboro;
}
.sarabun-font {
  font-family: "Sarabun" !important;
}
.f-14 {
  font-size: 14px;
}
.f-18 {
  font-size: 18px;
}
.rdt_TableCol div div {
  font-size: 13px;
  line-height: 1.3;
  font-weight: bold;
  text-align: center;
}
.custombtinmodal {
  background-color: #068f97 !important;
}
.custombtCancel {
  border-color: #ff9630 !important;
  color: #ff9630 !important;
  background-color: transparent !important;
  border: 1px solid #ff9630 !important;
  &:hover {
    border-color: #ff9630 !important;
    border: 1px solid #ff9630 !important;
    color: #ff9630 !important;
    background-color: rgba(233, 236, 239, 0.5) !important;
    box-shadow: 0 8px 25px -8px #fff !important;
  }
  &:active {
    background: #ff9630 !important;
    color: #fff !important;
  }
}
.dotActive {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #267659;
}
.dotunActive {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #ea5455;
}
.pPadding4px {
  padding-left: 4px !important;
}
.valid-selectbox-Custom {
  border: 1px solid #ea5455;
  border-radius: 6px;
}
.custom-margin {
  margin: auto 3px;
}

// for datepicker
.ant-input {
  color: #5f5f5f;
  height: calc(1.25 * 1em + 1.4rem + 1px);
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
  .react-datepicker__close-icon {
    font-family: "Font Awesome 5 Free";
    &:hover {
      &::after {
        opacity: 0.8;
      }
    }
  }
}
.datepicker-error {
  border: 1px solid #ea5455;
  border-radius: 6px;
}
.z-index10 {
  z-index: 10;
}
button.borderless {
  border: none;
}
.input-calendar {
  display: flex;
  input {
    padding-left: 8px;
  }
  .react-datepicker__close-icon {
    background-color: #fff;
    margin: 4px 2px;
    height: 30px;
    border-radius: 5px;
    z-index: 2;
  }
  .postion-calendar {
    position: absolute;
    right: 9px;
    top: 11px;
    z-index: 1;
  }
}
// end for datepicker

// .form-group > *{
//   font-family: "Sarabun", "Kanit", Helvetica, Arial, serif !important;
// }

.sweet-alert {
  div,
  h2 {
    font-family: "Sarabun";
  }
}

//custom menu sidebar ให้เห็นข้อความมากขึ้น
.main-menu.menu-light .navigation li.nav-item {
  white-space: unset;
  a {
    padding: 10px 5px 10px 10px !important;
    .menu-text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      padding-left: 17px;
      position: relative;
      white-space: unset;
      svg {
        position: absolute;
        left: 0px;
        top: 6px;
      }
    }
  }
}
